<template>
  <div class="card auto-responder-step">
    <div v-if="cloneCoupon" class="card-body">
      <ValidationObserver v-slot="{ handleSubmit }" ref="createForm">
        <form @submit.prevent="handleSubmit(create)">
          <h5>Merge Fields</h5>
          <div>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm mr-2 mb-2"
              @click="appendField('[company]')"
            >
              Company (company name)
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm mr-2 mb-2"
              @click="appendField('[dynamic_code]')"
            >
              Code (offer code)
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm mr-2 mb-2"
              @click="appendField('[first_name]')"
            >
              First Name
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm mr-2 mb-2"
              @click="appendField('[expiration_date]')"
            >
              Date Offer Expires (date offer expires)
            </button>
            <button
              type="button"
              class="btn btn-outline-dark btn-sm"
              @click="appendField('[business_phone]')"
            >
              Business phone
            </button>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                v-slot="{ failed, errors }"
                rules="required"
                name="reminder_message"
              >
                <label>
                  Message
                  <span
                    v-b-tooltip.hover
                    style="vertical-align:sub;cursor:pointer;"
                    title="Please confirm that [dynamic_code] keyword is included in message to send redemption code to customers."
                    ><i class="uil uil-question-circle"></i>
                  </span>
                </label>
                <textarea
                  v-model="cloneCoupon.reminder_message"
                  name="reminder_message"
                  class="form-control"
                  rows="2"
                  :class="{ 'is-invalid': failed }"
                  @focus="selectEditor"
                ></textarea>
                <b-form-invalid-feedback v-if="errors.length">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                <SMSLength :text="cloneCoupon.reminder_message" />
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <SelectInput
                v-model="cloneCoupon.day_to_expire"
                :options="timeLimitExpireOptions"
                name="day_to_expire"
                label="Day to Expire"
              />
            </div>
          </div>

          <hr />
          <h5>Reverse Auto Responder</h5>
          <p
            >These messages will go out the number of days prior to the offer
            expiring, this creates urgency and increases conversion rates.</p
          >
          <table class="table table-bordered mt-4 mb-4">
            <thead>
              <tr>
                <th style="width:40%">Time</th>
                <th style="">Message</th>
                <th style="width:100px;"> </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(reminder, index) in cloneCoupon.reminders"
                :key="index"
              >
                <td>
                  <div class="row">
                    <div class="col-md-6">
                      <SelectInput
                        v-model="reminder.days"
                        :options="timeLimitOptions"
                        label="Days"
                        name="Days"
                      />
                    </div>
                    <div class="col-md-6">
                      <label>Time</label>
                      <flat-pickr
                        v-model="reminder.time"
                        :config="timePicker"
                        class="form-control"
                        placeholder="Pick a time"
                      ></flat-pickr>
                    </div>
                  </div>
                </td>
                <td>
                  <ValidationProvider
                    v-slot="{ failed, errors }"
                    rules="required"
                    name="message"
                  >
                    <textarea
                      v-model="reminder.message"
                      name="message"
                      class="form-control mt-3"
                      rows="2"
                      :class="{ 'is-invalid': failed }"
                      @focus="selectEditor"
                    ></textarea>
                    <b-form-invalid-feedback v-if="errors.length">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                    <SMSLength :text="reminder.message" />
                  </ValidationProvider>
                </td>
                <td class="text-center align-middle">
                  <button
                    class="btn btn-outline-danger btn-sm"
                    type="button"
                    @click="removeRemimder(index)"
                  >
                    <i class="uil uil-trash-alt"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td class="text-center">
                  <button
                    class="btn btn-outline-success btn-sm"
                    type="button"
                    @click="addReminder"
                  >
                    <i class="uil uil-plus"></i> Add
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />

          <div class="mt-2">
            <h5><strong><span v-b-tooltip.hover title="You can edit this timezone in settings page.">Timezone: {{ settingTimezone }}</span></strong></h5>
            <div class="mt-2">Local Timezone: {{ localTimeZone }}</div>
            <div>Local Time: {{ localTime }}</div>
            <div>UTC Time: {{ utcTime }}</div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import SMSLength from '../../../Common/SMSLength'

export default {
  components: { SMSLength },

  props: {
    coupon: {
      type: [Object],
      default: null,
    },
  },

  data() {
    return {
      reminderTimeOptions: [
        { label: '0.5 hr', value: 30 },
        { label: '1 hr', value: 60 },
        { label: '2 hr', value: 120 },
        { label: '3 hr', value: 180 },
        { label: '12 hr', value: 720 },
        { label: '24 hr', value: 1440 },
      ],
      cloneCoupon: null,
      dateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:00',
      },
      timePicker: {
        wrap: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      selectedEditor: null,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    localTimeZone() {
      let timezone = moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },
    localTime() {
      return moment().format('MMM D, YYYY hh:mm A')
    },
    utcTime() {
      return moment()
        .utc()
        .format('MMM D, YYYY hh:mm A')
    },

    timeLimitExpireOptions() {
      return Array(31)
        .fill()
        .map((_, i) => {
          return { value: i + 1, label: i + 1 }
        })
    },

    timeLimitOptions() {
      return Array(32)
        .fill()
        .map((_, i) => {
          return { value: i, label: i }
        })
    },

    settingTimezone() {
      let timezone = this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()

      return `(UTC${moment()
        .tz(timezone)
        .format('Z')}) ${timezone}`
    },
  },

  watch: {
    coupon: {
      deep: true,
      handler(oldVal, newVal) {
        this.cloneCoupon = JSON.parse(JSON.stringify(this.coupon))
        this.cloneCoupon.timezone =
          this.cloneCoupon.timezone || moment.tz.guess()
      },
    },
  },

  mounted() {
    this.cloneCoupon = JSON.parse(JSON.stringify(this.coupon))
  },

  methods: {
    blurEditor() {
      this.selectedEditor = null
    },

    selectEditor(event) {
      this.selectedEditor = event.target
    },

    appendField(field) {
      if (!this.selectedEditor) return

      this.selectedEditor.focus()
      document.execCommand('insertText', false, field)
    },

    addReminder() {
      this.cloneCoupon.reminders.push({ days: 1, message: '', time: '09:00 AM' })
    },

    removeRemimder(index) {
      this.cloneCoupon.reminders.splice(index, 1)
    },

    async validateStep() {
      let stepValid = false

      await this.$refs.createForm.validate().then(async (isValid) => {
        if (isValid) {
          stepValid = await this.update()
        }
      })

      return stepValid
    },

    async update() {
      let stepValid = false
      this.$emit('loading', true)

      await this.$store
        .dispatch('coupon/update', this.cloneCoupon)
        .then((coupon) => {
          this.cloneCoupon = coupon
          this.$emit('done', coupon)
          this.$emit('loading', false)
          stepValid = true
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.createForm.setErrors(err.response.data.errors)
          }
          this.$emit('loading', false)
          stepValid = false
        })

      return stepValid
    },
  },
}
</script>

<style lang="scss">
.auto-responder-step {
  max-width: 1140px;
  margin: 0 auto;
  .table {
    .form-group {
      margin-bottom: 0px;
    }
    textarea {
      resize: none;
    }
    td {
      vertical-align: top;
    }
  }
  .form-control.flatpickr-input {
    background: #fff !important;
  }
}
</style>
